<template>
    <el-menu default-active="4" class="el-menu-demo" mode="horizontal" :ellipsis="false">
        <el-menu-item index="0">VisionTec</el-menu-item>
        <div class="flex-grow" />
        <el-menu-item index="1">
            <el-link type="info" :underline="false" href="storeHome">营业报表</el-link>
        </el-menu-item>
        <el-menu-item index="2"> <el-link type="info" :underline="false" href="invoiceTable">发票信息</el-link> </el-menu-item>
        <el-menu-item index="3">
            <el-link type="info" :underline="false" href="userManage">员工管理</el-link>
        </el-menu-item>
        <el-menu-item index="4">
            <el-link type="info" :underline="false" href="storeManage">商店管理</el-link>
        </el-menu-item>

    </el-menu>

    <!-- 店铺账号 -->
    <el-scrollbar :height="(height - 150) + 'px'">
        <el-form label-width="220px">

            <el-form-item label=" NIE/CIF ">
                <el-input v-model="StoreInvoiceOpt.NIF" />
            </el-form-item>
            <el-form-item label="Nombre">
                <el-input v-model="StoreInvoiceOpt.Nombre" />
            </el-form-item>
            <el-form-item label="Province">
                <el-select v-model="StoreInvoiceOpt.ProvinceId" class="m-2" size="mini">
                    <el-option v-for="item in ProvinceList" :key="item.Id" :label="item.Name" :value="item.Id" />
                </el-select>
            </el-form-item>
            <el-form-item label="Certificate">
                <el-col :span="6">
                    <el-input v-model="StoreInvoiceOpt.CertificateName"></el-input>
                </el-col>
                <el-col :span="2" style="text-align: center;">
                    -
                </el-col>
                <el-col :span="11">
                    <el-upload v-model:file-list="fileList" class="upload-demo" :auto-upload="true"
                        :action="UploadCertificateUrl" multiple :on-preview="handlePreview" :on-remove="handleRemove"
                        :on-success="UploadPictureSuccess" :before-remove="beforeRemove" :limit="1"
                        :on-exceed="handleExceed" :headers="uploadHeaders">
                        <el-button type="primary">upload</el-button>
                        <template #tip>
                            <div class="el-upload__tip">
                            </div>
                        </template>
                    </el-upload>

                </el-col>

            </el-form-item>

            <el-form-item label="Contraseña Certificado">
                <el-col :span="6">
                    <el-input v-model="StoreInvoiceOpt.CertificatePassword"></el-input>
                </el-col>
                <el-col :span="2" style="text-align: center;">
                    -
                </el-col>
                <el-col :span="11">
                    不修改请留空
                </el-col>

            </el-form-item>

            <!-- <el-form-item label="prueba">
                <el-switch v-model="StoreInvoiceOpt.IsSandbox" class="ml-2"
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
            </el-form-item> -->


            <!-- <el-divider content-position="left">上一次签名的信息</el-divider>
            <el-form-item label="SignatureValueFirmaFacturaAnterior">
                <el-input v-model="StoreInvoiceOpt.SignatureValueFirmaFacturaAnterior"></el-input>
            </el-form-item>

            <el-form-item label="NumFacturaAnterior">
                <el-input v-model="StoreInvoiceOpt.NumFacturaAnterior"></el-input>
            </el-form-item>

            <el-form-item label="FechaExpedicionFacturaAnterior">
                <el-input v-model="StoreInvoiceOpt.FechaExpedicionFacturaAnterior"></el-input>
            </el-form-item>

            <el-form-item label="SerieFacturaAnterior">
                <el-input v-model="StoreInvoiceOpt.SerieFacturaAnterior"></el-input>
            </el-form-item> -->

            <el-divider content-position="left">公司信息</el-divider>

            <el-form-item label="LicenciaTBAI">
                <el-input disabled v-model="StoreInvoiceOpt.SignatureSoftware.LicenciaTBAI"></el-input>
            </el-form-item>
            <el-form-item label="NIE/CIF ">
                <el-input disabled v-model="StoreInvoiceOpt.SignatureSoftware.NIF"></el-input>
            </el-form-item>
            <el-form-item label="Nombre Software">
                <el-input disabled v-model="StoreInvoiceOpt.SignatureSoftware.Nombre"></el-input>
            </el-form-item>
            <el-form-item label="Version">
                <el-input disabled v-model="StoreInvoiceOpt.SignatureSoftware.Version"></el-input>
            </el-form-item>

        </el-form>

    </el-scrollbar>
    <div style="width: 100%;text-align:center;">
        <el-row>
            <el-col :span="6">
                <el-button style="width: 200px;" type="primary" @click="SaveStoreInvoice()">保存</el-button>
            </el-col>
            <el-col :span="2" style="text-align: center;">

            </el-col>
            <el-col :span="11">

            </el-col>
        </el-row>

    </div>
 
</template>
<script >
import axios from "../../../commons/AxiosMethod.js"
import tools from "../../../commons/tools.js"
import "../../../assets/css/commons.css"
import { ElMessageBox, ElMessage } from "element-plus"

export default {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data() {
        return {
            height: window.innerHeight,
            width: window.innerWidth,
            StoreInvoiceOpt: {
                SignatureSoftware: {}
            },
            ProvinceList: [],
            UploadCertificateUrl: '',
            ApiUrl: '',
            uploadHeaders: {}
        }
    },
    name: "App",
    mounted() {
        this.GetMyStoreInvoiceInfo();
        this.GetProvinceList();
        this.ApiUrl = axios.GetUrl();
        this.UploadCertificateUrl = this.ApiUrl + "/api/Upload/UploadCertificate";
        this.uploadHeaders = {
            Authorization: localStorage.getItem("Authentication_Header")
        }
    },
    components: {},
    methods: {

        //保存商户发票信息
        SaveStoreInvoice() {

            axios.apiMethod(
                "/stores/store/SaveStoreInvoice",
                "post",
                this.StoreInvoiceOpt,
                (result) => {

                    if (result.Data) {
                        this.StoreInvoiceWindow = false;
                        this.GetStoreList();
                    }

                }
            );

        },
        // 文件上传成功操作
        UploadPictureSuccess(response, file, fileList) {

            this.StoreInvoiceOpt.CertificateName = response.Data.FileName;
            this.StoreInvoiceOpt.CertificatePath = response.Data.Folder;

        },
        //获取省份列表
        GetProvinceList() {
            axios.apiMethod(
                "/api/Invoice/GetProvinceList",
                "get",
                {

                },
                (result) => {
                    this.ProvinceList = result.Data;
                }
            );
        },
        //打开商户发票信息窗口
        GetMyStoreInvoiceInfo() {

            axios.apiMethod(
                "/stores/store/GetMyStoreInvoiceInfo",
                "get",
                {
                },
                (result) => {
                    this.StoreInvoiceOpt = result.Data;
                }
            );
            this.StoreInvoiceWindow = true;

        },
    }
}
</script>
  
<style scoped>
.flex-grow {
    flex-grow: 1;
}
</style>
  